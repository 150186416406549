<template>
	<div style="background-color:rgba(255,255,255,.6); padding:10px">
		<h1>View op {{subjectName}} met Excel mogelijkheden </h1>
		<hr />
		<tbl-xls-viewer
			:items="items"
			:aStandardSelFields="aStandardSelFields"
			:subjectName=subjectName
		/>
	</div>
</template>

<script>
import TblXlsViewer from "@/components/TblXlsViewer";
import { getStudents } from "@/api/student.js";

export default {
	//name: "",
	components: {
		TblXlsViewer,
	},

	data: () => ({
		items: [],
		bLoaded: false,
        subjectName: "studenten",
		aStandardSelFields: ["stdnr", "naam", "groep"],
	}),

	created() {
        getStudents().then((apiData) => {
            console.log("getStudents() apiData:", apiData);
			if (apiData.data.bSuccess) {
                    this.bLoaded = true;
                    this.items = apiData.data.data;
                } else {
                    this.$root.$emit("showSnackbar", {
                        text: "2) "+apiData.data.sMessage,
                        type: "error",
                        time: 7000,
                    });
                }
            return null //response.data.data
        });
	},
};
</script>